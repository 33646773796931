<template>
  <article>
    <section class="secao-transparencia container-base">
      <section class="breadcrumb">
        <router-link :to="{ name: 'Home' }" class="breadcrumbT">
          <img src="@/assets/BreadcrumbHome.png" alt="Home" class="breadcrumbImg">
        </router-link>
        <img src="@/assets/IconeSeta.png" alt="Home" class="iconImg">
        <router-link :to="{ name: 'Transparencia' }" class="breadcrumbT">
          <img src="@/assets/listaBreadcrumb.png" alt="Lista" class="breadcrumbImg">
        </router-link>
        <img src="@/assets/IconeSeta.png" alt="Home" class="iconImg">
        <h1>Transparência Institucional</h1>
      </section>
      <p class="subh">
        Clique no documento para acessar em PDF
      </p>
      <div class="lista-transparencia">
        <a
          v-for="(doc, i) in documentos"
          :key="i" class="documento"
          :href="doc.arquivo? doc.arquivo.data.full_url : null"
          target="_blank"
          :style="{'animation-delay': .1 + .03*i + 's'}"
          :class="{loading}"
        >
          <img src="@/assets/ic_download_arquivo.svg" class="icone-download">
          <transition name="crossfade" mode="out-in">
            <span v-if="!loading" key="titulo">{{ doc.titulo }}</span>
            <span v-else class="tituloSkeleton" key="skeleton">Carregando{{ Array(Math.floor(Math.random() * 16)).fill('.').join('') }}</span>
          </transition>
        </a>
      </div>
    </section>
  </article>
</template>

<style lang="scss" scoped>

   h1{
    display: block;
    margin: 3px 0 0 9px;
    font-size: 25px;
  }

  .breadcrumb{
    display: flex;
  }

  .breadcrumbImg{
    margin: 6px 9px 0 9px;
    width: 25px;
    height: 25px;
  }

  .iconImg{
    margin: 13px 9px 0 9px;
    width: 6px;
    height: 10px;
  }

  @media (max-width: 430px) {
    .iconImg{
    margin: 13px 9px 0 9px;
    width: 4px;
    height: 8px;
    }

    .breadcrumbImg{
    margin: 6px 9px 0 9px;
    width: 20px;
    height: 20px;
    }

    h1{
    display: block;
    margin: 5px 0 0 9px;
    font-size: 18px;
    }
  }

  .secao-transparencia {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .lista-transparencia {
    display: grid;
    flex-direction: row;
    flex-wrap: wrap;

    grid-template-columns: 1fr 1fr;
    gap: 0 20px;

    @media screen and (max-width: 680px) {
      grid-template-columns: 1fr;
    }

    .documento {
      display: flex;
      flex: 1 0 320px;

      flex-direction: row;
      align-items: center;

      border-bottom: 1px solid gray;

      transition: background-color .2s;

      text-decoration: none;
      color: var(--ahbb-blue);

      animation: fade-down .1s ease-out both;

      &:not(.loading) {
        &:hover {
          background-color: var(--ahbb-green-light-3);
          cursor: pointer;
        }

        &:active {
          background-color: var(--ahbb-green-light-2);
        }
      }
      
      .icone-download {
        width: 24px;
        height: 24px;
        margin: 8px;
      }

      .tituloSkeleton{
        color: transparent;
        animation: pulse-bg 1s infinite;
      }

      @keyframes fade-down {
        0% {
          transform: translateY(-10px);
          opacity: 0;
        }
      }

      @keyframes pulse-bg {
        0% {
          background-color: #eee;
        }
        50% {
          background-color: rgba(233, 233, 233, 0.993);
        }
        100% {
          background-color: #eee;
        }
      }
    }
  }

  .crossfade-enter-active, .crossfade-leave-active {
    animation: none !important;
    transition: opacity .15s ease-in-out !important;
  }

  .crossfade-enter, .crossfade-leave-to {
    opacity: 0 !important;
  }

</style>

<script>
import cms from '@/lib/cms.js'

export default {
  name: 'TransparenciaInstitucional',
  data: () => ({
    documentos: Array(20).fill({ titulo: 'Teste' }),
    loading: true,
  }),
  async created() {
    this.loading = true
    this.documentos = (await cms.getItems('documentos_transparencia', { fields: '*,arquivo.data' })).data
    this.loading = false
  },
  metaInfo: {
    title: 'Transparência Institucional',
  },
  components: {
  },
}
</script>
